import { ReactNode } from "react";

export const LayoutEmpty = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return <>{children}</>;
};

export const getLayout = (page: ReactNode): JSX.Element => (
  <LayoutEmpty>{page}</LayoutEmpty>
);
