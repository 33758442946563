import { getLayout } from "components/layouts/LayoutEmpty";
import { Heading } from "components/atoms/Heading";
import { paths } from "enum/paths.enum";
import { Center } from "components/atoms/Center";
import { InternalLink } from "components/atoms/InternalLink";
import { Button } from "components/atoms/Button";

export default function Custom404() {
  return (
    <div className="w-screen h-screen p-12 text-center">
      <Center>
        <div>
          <Heading size="2xl" className="mb-6">
            Page not found
          </Heading>
          <InternalLink href={paths.DASHBOARD_INDEX}>
            <Button size="lg" colorScheme="primary">
              Back to dashboard
            </Button>
          </InternalLink>
        </div>
      </Center>
    </div>
  );
}

Custom404.getLayout = getLayout;
